import { FieldType } from '90.quickConnect.Models/enums';
import {
  Choice,
  FieldData,
  FieldDesc,
  AddressData,
  AttachmentItemData,
  HierarchicalChoice,
  DateTimeDesc,
  NotificationDesc,
} from '90.quickConnect.Models/models';
import { convertToBooleanValue } from '80.quickConnect.Core/helpers';
import { parseTimeToAPI, mapToFieldVisibility, mapActionData } from '90.quickConnect.Models/mappings';
import getDateWithFormat from '80.quickConnect.Core/helpers/dateFormat';
import { isDate, isDateTimeExtension, isQCNotification } from '80.quickConnect.Core/helpers/common';
import { parseNumber } from '80.quickConnect.Core/helpers/parseNumber';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import { isFieldDataArrayforFieldData } from '90.quickConnect.Models/guards/fields/isFieldDataArrayforFieldData';
import { isChoice, isEmptyAddressData } from '90.quickConnect.Models/guards';
import { HierarchicalListData } from '90.quickConnect.Models/models/fields/values/hierarchicalListData';

const mapToFieldDataCommon = (field: FieldDesc, useFullId: boolean): FieldData => {
  return {
    id: useFullId ? field.fullPathId : field.id,
    fieldType: field.fieldType,
    label: field.label !== '' ? field.label : '',
  } as FieldData;
};

const mapDateTimeValue = ({ value, type }: DateTimeDesc): string | null => {
  if (isDateTimeExtension(value)) return getDateWithFormat(value, type);

  if (typeof value === 'string') {
    const dateParsed: DateTimeExtension | null = DateTimeExtension.createLocalDateTimeFromString(value);

    if (!dateParsed) return null;
    return getDateWithFormat(new DateTimeExtension(dateParsed), type);
  }

  if (isDate(value)) return getDateWithFormat(new DateTimeExtension(value), type);

  return '';
};

const mapAddressValue = (data: AddressData): AddressData | null => {
  if (!data) return null;
  const addressData = {
    city: data.city ?? '',
    complement: data.complement ?? '',
    coordinates: data.coordinates,
    country: data.country ?? '',
    street: data.street ?? '',
    streetNumber: data.streetNumber ?? '',
    zipCode: data.zipCode ?? '',
  } satisfies AddressData;

  return isEmptyAddressData(addressData) ? null : addressData;
};

// Function to recursively filter and map visible fields
// It checks 'isVisible' to include only visible fields and applies recursively for nested items.
const filterAndMapVisibleFields = (items: FieldDesc[], useFullId: boolean): FieldData[] => {
  return items.reduce((acc, item) => {
    if (item.isVisible) {
      const mappedField: FieldData = {
        ...mapToFieldDataCommon(item, useFullId),
        value: item.items ? filterAndMapVisibleFields(item.items, useFullId) : item.value,
      };
      acc.push(mappedField);
    }
    return acc;
  }, [] as FieldData[]);
};

/* eslint-disable max-lines-per-function */
const mapToFieldData = (field: FieldDesc, useFullId = false): FieldData => {
  switch (field.fieldType) {
    case FieldType.Text:
      const textField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: field.value ?? '',
      };
      return textField;
    case FieldType.Digits:
      const digitField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: isNaN(parseInt(field.value as string)) ? null : parseInt(field.value as string),
      };
      return digitField;
    case FieldType.Numeric:
      const numericField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value:
          typeof field.value === 'number'
            ? field.value
            : parseNumber(field.value as string) === undefined
            ? null
            : parseNumber(field.value as string),
      };
      return numericField;
    case FieldType.Include:
    case FieldType.RepeatableGroup:
    case FieldType.Step:
    case FieldType.Group:
    case FieldType.ImagesGroup:
      const groupField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: filterAndMapVisibleFields(field.items, useFullId),
      };
      return groupField;
    case FieldType.Dialog:
      const itemsValue: FieldData[] = field.items.filter((i) => mapToFieldVisibility(i)).map((i) => mapToFieldData(i));
      // const status = field.errors && field.errors.length > 0 ? 'invalid' : 'valid';
      const dialogField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: itemsValue,
      };
      return dialogField;
    case FieldType.DateTime:
      const dateTimeField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: mapDateTimeValue(field as DateTimeDesc),
      };
      return dateTimeField;
    case FieldType.Time:
      const timeValue = typeof field.value === 'string' ? new Date(field.value) : (field.value as Date);

      const timeField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: timeValue && !isNaN(timeValue.getTime()) ? parseTimeToAPI(timeValue) : '',
      };

      return timeField;
    case FieldType.CheckBox:
      const booleanValue = convertToBooleanValue(field.value);
      const checkBoxField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: booleanValue === true ? 1 : 0,
      };
      return checkBoxField;
    case FieldType.CheckBoxList:
      const checkBoxListField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: (field.value as Choice[]) ?? [],
      };
      return checkBoxListField;
    case FieldType.RadioList:
      const radioListField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: (field.value as Choice) ?? null,
      };
      return radioListField;

    case FieldType.Alert:
      const alertField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: (field.value as Choice) ?? null,
      };
      return alertField;
    case FieldType.Combo:
      const comboField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: (field.value as Choice) ?? null,
      };
      return comboField;
    case FieldType.Address:
      const addressField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: mapAddressValue((field.value as AddressData) ?? null),
      };
      return addressField;
    case FieldType.Attachment:
      const attachField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: (field.value as AttachmentItemData[]) ?? [],
      };
      return attachField;
    case FieldType.Photo:
    case FieldType.Signature:
      const photoField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: (field.value as AttachmentItemData[]) ?? [],
      };
      return photoField;

    case FieldType.Counter:
      const counterField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: field.value ?? null,
      };
      return counterField;

    case FieldType.Notification:
      const notificationField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: isQCNotification(field.value)
          ? { ...field.value, notificationType: (field as NotificationDesc).notificationType }
          : null,
      };
      return notificationField;
    case FieldType.Label:
      const labelField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        label: typeof field.value === 'string' ? field.value : field.label ?? '',
        value: null,
      };
      return labelField;

    case FieldType.HierarchicalList:
      let hierarchicalValue: Choice[] = [];

      if (field.value && field.value !== null) {
        if (Array.isArray(field.value)) {
          hierarchicalValue = (
            (
              field.value as (
                | string
                | Choice
                | HierarchicalChoice
                | AttachmentItemData
                | HierarchicalListData
                | FieldData
              )[]
            ).filter(
              (c: string | Choice | HierarchicalChoice | AttachmentItemData | HierarchicalListData | FieldData) =>
                isChoice(c),
            ) as Choice[]
          ).map((c: Choice) => ({
            label: c.label,
            value: c.value,
            data: c.data,
          }));
        } else if (isChoice(field.value)) {
          hierarchicalValue = [
            {
              label: field.value.label,
              value: field.value.value,
              data: field.value.data,
            },
          ];
        }
      } else {
        hierarchicalValue = [];
      }

      const hierarchicalField: FieldData = {
        ...mapToFieldDataCommon(field, useFullId),
        value: hierarchicalValue,
      };
      return hierarchicalField;

    case FieldType.Slider:
      const sliderValue =
        field.value !== undefined || field.value !== null
          ? typeof field.value === 'number'
            ? field.value
            : typeof field.value === 'string' && !isNaN(Number(field.value))
            ? Number(field.value)
            : null
          : null;
      return {
        ...mapToFieldDataCommon(field, useFullId),
        value: sliderValue,
      };

    // N'est pas utilisé actuellement en tant que champ mais sert notamment pour stocker de la donnée (cf. internalData de type DataSource)
    case FieldType.DataSource:
      const { value } = field;
      return {
        ...mapToFieldDataCommon(field, useFullId),
        value: isFieldDataArrayforFieldData(value) ?? [],
      };

    case FieldType.Audio:
    case FieldType.Draw:
    case FieldType.Geolocation:
    case FieldType.Compute:
      return {
        ...mapToFieldDataCommon(field, useFullId),
        value: field.value ? (typeof field.value === 'number' ? field.value.toString() : field.value) : '',
      };
    case FieldType.FixedImage:
    case FieldType.FixedAttachment:
      return {
        ...mapToFieldDataCommon(field, useFullId),
        value: field.value ?? null,
      } as FieldData;

    case FieldType.Action:
      return {
        ...mapToFieldDataCommon(field, useFullId),
        value: mapActionData(field),
      };

    case FieldType.Array:
    case FieldType.Separator:
    case FieldType.CodeReader:
    case FieldType.Plugin:
    case FieldType.ReadOnlyValue:
    case FieldType.RfidReader:
    case FieldType.TodoList:
    case FieldType.Unknow:
    default:
      return {
        ...mapToFieldDataCommon(field, useFullId),
        value: field.value ?? '',
      } as FieldData;
  }
};

export default mapToFieldData;
