import React, { useRef } from 'react';
import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  useTheme,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  IconButton,
} from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { useTranslation } from 'react-i18next';
import useData from '../../Containers/DialogQcField/hooks';
import useDataAction from './hooks';
import { QcActionName } from './const';
import { QCActionValue } from './types';
import { ContainerFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { FieldMainBox } from '50.quickConnect.Fields/styles';
import { Choice, DialogDesc, QcActionDesc } from '90.quickConnect.Models/models';
import { DialogQcFieldName } from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/const';

import {
  DialogActionsStyle,
  DialogCancelButtonCustom,
  DialogCancelButtonStyle,
  DialogContentStyle,
  DialogStyle,
  DialogValidateButtonStyle,
} from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/styles';
import getFieldKey from '50.quickConnect.Fields/helpers/getFieldKey';
import { C2SImportant } from '50.quickConnect.Fields/Fields/styles';
import FieldRenderer from '50.quickConnect.Fields/Fields/FieldRenderer';
import { ListColumnForCustomListContainer } from '10.quickConnect.app/components/domain/Home/styles';
import MandatoryField from '10.quickConnect.app/components/domain/Home/Shared/MandatoryFields';
import CheckBoxListItem from '50.quickConnect.Fields/FieldsTypes/Lists/CheckBoxListQcField/CheckBoxListRightContent/CheckBoxListItem';

const QcAction = ({
  field,
  updateDeclaration,
  flattenFields,
  labelSpan,
  isReadOnly,
  context,
  selectedIndex,
  setSelectedIndex,
  updateFieldErrors,
  setDeclaration,
  updateItemsForThisField,
}: ContainerFieldsTypesProps) => {
  const actionField = field as QcActionDesc & DialogDesc;
  const nameWithId = `${QcActionName}-${actionField.id}`;
  const { fullPathId, items } = actionField;

  const { handleOpen, handleCancel, handleSubmit, open, dialogQCFieldRef } = useData(
    updateDeclaration,
    actionField,
    setSelectedIndex,
    updateFieldErrors,
    updateItemsForThisField,
  );
  const {
    handleSearch,
    serachedList,
    handleNext,
    handlePrevious,
    updateLocalState,
    localValue,
    compteur,
    canSearch,
    handleDelete,
    updateCheckBoxLocalState,
    localValueForCheckBox,
    initialSearch,
  } = useDataAction(
    actionField,
    setSelectedIndex,
    context,
    updateDeclaration,
    field,
    open,
    flattenFields,
    handleSubmit,
  );
  const theme = useTheme();
  const scrollTarget = useRef<HTMLElement>();
  const { t } = useTranslation('declaration');
  return (
    <Box sx={FieldMainBox(theme.breakpoints)} data-cy={nameWithId}>
      <Card>
        <CardContent>
          {/* <Typography sx={{ fontSize: 14 }} component="span"> */}
          {labelSpan}
          {/* </Typography> */}
          <Button
            variant="contained"
            fullWidth
            onClick={actionField && actionField.isPopup === true ? handleOpen : initialSearch}
            disabled={isReadOnly}
          >
            {actionField.actionLabel ?? 'Action'}
          </Button>
          {Array.isArray(actionField?.value) && Array.isArray((actionField.value as QCActionValue[])[0]?.value)
            ? (actionField.value as QCActionValue[])[0]?.value.map((v, index) => (
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
                  key={`${v.value ?? v.label}-${index}`}
                >
                  <Typography
                    sx={{ fontSize: 14 }}
                    component="span"
                    dangerouslySetInnerHTML={{ __html: v.label ?? '' }}
                  />
                  <IconButton
                    aria-label="delete"
                    data-cy={`${fullPathId}-delete`}
                    disabled={isReadOnly}
                    onClick={() => handleDelete(v.value as string)}
                  >
                    <DeleteOutlineRoundedIcon />
                  </IconButton>
                </Box>
              ))
            : null}
        </CardContent>
      </Card>
      {open && open === fullPathId && (
        <Box data-cy={DialogQcFieldName} ref={dialogQCFieldRef} sx={DialogStyle(theme.breakpoints, theme.palette)}>
          <Box sx={DialogContentStyle(theme.breakpoints)} data-cy={`${DialogQcFieldName}-content`}>
            <Box sx={{ mb: 2 }}>
              <Typography component="span" dangerouslySetInnerHTML={{ __html: actionField.label ?? '' }} />
            </Box>
            {items.map((item) => {
              const key = getFieldKey(item);
              return item?.importance ? (
                <Box sx={C2SImportant(item.importance)}>
                  <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
                    <FieldRenderer
                      key={key}
                      field={item}
                      updateItemsForThisField={updateItemsForThisField}
                      updateDeclaration={updateDeclaration}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                      isReadOnly={isReadOnly || item.fieldIsReadOnly}
                      flattenFields={flattenFields ?? []}
                      setDeclaration={setDeclaration}
                      context={context}
                      updateFieldErrors={updateFieldErrors}
                    />
                  </MandatoryField>
                </Box>
              ) : (
                <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
                  <FieldRenderer
                    key={key}
                    field={item}
                    updateDeclaration={updateDeclaration}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    isReadOnly={isReadOnly || item.fieldIsReadOnly}
                    updateItemsForThisField={updateItemsForThisField}
                    flattenFields={flattenFields ?? []}
                    setDeclaration={setDeclaration}
                    context={context}
                    updateFieldErrors={updateFieldErrors}
                  />
                </MandatoryField>
              );
            })}
            <Button
              variant="contained"
              fullWidth
              disabled={!canSearch}
              onClick={() => (!canSearch ? undefined : handleSearch())}
            >
              {'Rechercher'}
            </Button>

            {serachedList && serachedList.resultList?.length > 0 && (
              <>
                <Box
                  data-cy={'serachedList'}
                  sx={{ ...ListColumnForCustomListContainer(theme), width: '100%', height: '50%' }}
                  ref={scrollTarget}
                >
                  {actionField.isMultiSelection ? (
                    serachedList.resultList.map((option, index) => (
                      <CheckBoxListItem
                        key={`${nameWithId}-${option.value}`}
                        parentId={option.value}
                        updateLocalState={updateCheckBoxLocalState}
                        choice={option}
                        localValue={localValueForCheckBox}
                      />
                    ))
                  ) : (
                    <FormControl data-cy={nameWithId} sx={{ width: '100%' }}>
                      <RadioGroup sx={{ marginTop: '10px' }} value={localValue || ''} onChange={updateLocalState}>
                        {serachedList.resultList.map((option, index) => (
                          <FormControlLabel
                            data-cy={`${nameWithId}-${option.value ?? option.label}`}
                            key={index}
                            value={option.value ?? option.label}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <Button
                    variant="contained"
                    onClick={() => (compteur > 0 ? handlePrevious() : undefined)}
                    sx={{ visibility: `${compteur > 0 ? '' : 'hidden'}` }}
                  >
                    {t('formlib_action_button_previous')}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => (serachedList.hasMoreData ? handleNext() : undefined)}
                    sx={{ visibility: `${serachedList.hasMoreData ? '' : 'hidden'}` }}
                  >
                    {t('formlib_action_button_next')}
                  </Button>
                </Box>
              </>
            )}
          </Box>

          <Box sx={DialogActionsStyle(theme.breakpoints, theme.palette)}>
            <DialogCancelButtonCustom
              variant="contained"
              data-cy={`${nameWithId}-cancel`}
              color="secondary"
              sx={DialogCancelButtonStyle(theme.breakpoints, theme.palette)}
              onClick={handleCancel}
            >
              {t('qcapp_dialog_action_cancel')}
            </DialogCancelButtonCustom>
            <Button
              variant="contained"
              data-cy={`${nameWithId}-submit`}
              sx={DialogValidateButtonStyle(theme.breakpoints, theme.palette)}
              onClick={handleSubmit}
            >
              {t('qcapp_dialog_action_validate')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default QcAction;
