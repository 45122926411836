import { useState } from 'react';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

export enum ChantierStatus {
  COMPLETED,
  INCOMPLETE,
  CLOSED,
}

export type Chantier = {
  id: string;
  name: string;
  status: ChantierStatus;
  published: boolean;
  icon: string;
};

/*
TODO KTY MOCK DATA
 */

const mockChantiers: Array<Chantier> = [
  {
    id: 'jesuisunId',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: true,
    name: 'Petit Nom Minion',
    status: ChantierStatus.COMPLETED,
  },
  {
    id: 'jesuisunIdAussi',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: false,
    name: "Je suis un grand nom de chantier histoire de pousser les limites de l'affichage et d'emerder le dev front end qui doit gérer des long noms",
    status: ChantierStatus.INCOMPLETE,
  },
  {
    id: 'IDTruc',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: true,
    name: 'Nom de chantier',
    status: ChantierStatus.CLOSED,
  },
  {
    id: 'IDSomething',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: true,
    name: 'Nom de Chantier',
    status: ChantierStatus.INCOMPLETE,
  },
  {
    id: 'IDMachin',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: false,
    name: 'Nom de ChAnTiEr',
    status: ChantierStatus.CLOSED,
  },
  {
    id: 'BonjourId',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: true,
    name: 'Nom de CHANTIER',
    status: ChantierStatus.CLOSED,
  },
];

/*
TODO KTY MOCK DATA
 */

type HomeContentProps = {
  chantierList: Array<Chantier>;
};

const useChantierListData = (): HomeContentProps => {
  const [chantierList, setChantierList] = useState<Array<Chantier>>([]);

  useEffectOnce(() => {
    /*TODO KTY faire un call api pour recup la liste des chantiers*/
    setChantierList(mockChantiers);
  });
  return { chantierList };
};

export default useChantierListData;
