import React from 'react';
import { Box, CircularProgress, ImageListItem, Paper, Tooltip } from '@mui/material';
import { NoPhotographyOutlined } from '@mui/icons-material';

// Types
import type { ImageGroupItemProps } from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/components/ImageGroupItem/types';

// Business
import useData from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/components/ImageGroupItem/hooks';
import {
  StyledBadge,
  StyledNoPhotoIcon,
} from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/components/ImageGroupItem/styles';

export default function ImageGroupItem({ fieldId, url }: ImageGroupItemProps) {
  const {
    srcSet,
    isChecked,
    handleClick,
    isMandatory,
    isVisible,
    handleImgSrcOnError,
    fallbackImg,
    label,
    recordedItems,
  } = useData(url, fieldId);

  if (!isVisible) return <></>;

  return (
    <Paper
      elevation={2}
      sx={{
        border: (theme) =>
          isChecked()
            ? `4px solid ${theme.palette.primary.main}`
            : isMandatory
            ? `4px solid ${theme.palette.error.main}`
            : 'unset',
        borderRadius: '10px',
        aspectRatio: 1,
        cursor: 'pointer',
      }}
    >
      <Box onClick={handleClick}>
        {srcSet.length > 0 ? (
          <Tooltip title={label}>
            <ImageListItem
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <StyledBadge badgeContent={recordedItems} color="primary">
                {fallbackImg ? (
                  <StyledNoPhotoIcon />
                ) : (
                  <img
                    src={srcSet}
                    onError={handleImgSrcOnError}
                    alt={typeof fieldId === 'string' ? fieldId : 'unknown alt'}
                    style={{
                      width: '125px',
                      height: '125px',
                      objectFit: 'contain',
                    }}
                  />
                )}
              </StyledBadge>
            </ImageListItem>
          </Tooltip>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Paper>
  );
}
