import React, { CSSProperties, FunctionComponent } from 'react';
import { Box } from '@mui/material';
import useChantierListData from './hookChantierList';
import ChantierCard from './ChantierCard';

const containerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  flexGrow: 1,
  flexWrap: 'wrap',
  width: '100%',
  height: '100%',
  gap: '2rem',
  padding: '2rem',
};

const ChantierList: FunctionComponent = () => {
  const { chantierList } = useChantierListData();
  return (
    <Box style={containerStyle}>
      {chantierList.map((chantier) => {
        return (
          <ChantierCard
            key={chantier.id}
            id={chantier.id}
            icon={chantier.icon}
            name={chantier.name}
            published={chantier.published}
            status={chantier.status}
          />
        );
      })}
    </Box>
  );
};

export default ChantierList;
