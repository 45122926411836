import React from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { Outlet } from 'react-router';
import useHomeData from './hookChantierHome';
import TopBar from '10.quickConnect.app/components/shared/LayOut/TopBar';

const HomeName = 'homeComponent';

const ChantierHome = () => {
  useHomeData();

  return (
    <Box data-cy={HomeName} sx={{ display: 'flex', flexDirection: 'column' }}>
      <TopBar pageTitle="" centerChildren={'COPILOT CHANTIER'} />
      <Outlet />

      {/* On ajoute la dialog concernant la prise en compte des pièces jointes */}
      {/*{sendAPI && <SendingAttachmentsDialog />}*/}
    </Box>
  );
};
export default observer(ChantierHome);
