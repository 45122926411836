import { useCallback, useMemo, useState } from 'react';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';
import { UseDataProps } from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/components/ImageGroupItem/types';
import { useImagesGroupContext } from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/contexts/imagesGroupContext';
import { CheckBoxDesc, FieldDesc } from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import { fieldIsMandatory } from '90.quickConnect.Models/guards';
import { useGetImagesSources } from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/components/ImageGroupItem/hooks/useGetImagesGroupSources';

export default function useData(url: string, fieldId: string): UseDataProps {
  const [fallbackImg, setFallbackImg] = useState<boolean>(false);
  const { flattenFields, handleClickImageItem } = useImagesGroupContext();

  const { srcSet } = useGetImagesSources(url);

  const field: FieldDesc = useMemo(() => {
    const result: FieldDesc | undefined = flattenFields.find(({ id }: FieldDesc) =>
      StringExtension.isTheSame(id, fieldId),
    );

    if (!result) throw new Error(`Aucun champ trouvé avec lid suivant: ${fieldId}`);

    return result;
  }, [fieldId, flattenFields]);

  const isMandatory = fieldIsMandatory(field);
  const { isVisible } = field;

  const isChecked = () => {
    if (field.fieldType !== FieldType.CheckBox) return false;

    const { value: checkboxValue } = field as CheckBoxDesc;

    return !!checkboxValue;
  };

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      handleClickImageItem(field);
    },
    [field, handleClickImageItem],
  );

  const handleImgSrcOnError = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      event.preventDefault();
      if (!fallbackImg) setFallbackImg(true);
    },
    [fallbackImg],
  );

  const getRepeatableGroupItemsRecorded = useCallback((child: FieldDesc) => {
    if (child.fieldType === FieldType.RepeatableGroup) return child.items.length;
    else if (child.items.length > 0) {
      return child.items.reduce((acc: number, current: FieldDesc): number => {
        return acc + getRepeatableGroupItemsRecorded(current);
      }, 0);
    } else return 0;
  }, []);

  const recordedItems = getRepeatableGroupItemsRecorded(field);

  return {
    recordedItems,
    srcSet,
    field,
    isChecked,
    handleClick,
    isMandatory,
    isVisible,
    fallbackImg,
    handleImgSrcOnError,
    label: field.label,
  };
}
