import { useNavigate } from 'react-router';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { useModuleContext, useModuleContextSetter } from '00.app/module/moduleContext';
import { AppModulesIDs } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';
import { useStore } from '30.quickConnect.Stores';
import { useNavigatorOnLine } from '80.quickConnect.Core/hooks';
import { QRSCAN, USER_SETTING } from '10.quickConnect.app/routes/paths';

const useData = (): UseDataProps => {
  // On récupère le store
  const {
    resetAllObservables,
    LoginStore: {
      logOutAsync,
      signInInfos: { userUPN },
      connectByMsal,
      setConnectByMsal,
      availableModules,
    },
    DeclarationStore: {
      sendLogsToServerAsync,
      isEditingCurrentDeclaration,
      editableDeclaration,
      sendingAttachments,
      attachmentsToSend,
      hasDraftsDcl,
    },
    LoginMsalStore: { isLogoutByMsal, setIsLogoutByMsal },
    CommonStore: { loadingRequests },
    UserSettingsStore: { muiMode, switchMuiMode },
  } = useStore();

  const setModule = useModuleContextSetter();
  const module = useModuleContext();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const [openSignOutDialog, setOpenSignOutDialog] = useState<boolean>(false);

  // On définit le state
  const { t: tAxios } = useTranslation('axios');

  // On ajoute des hooks
  const navigate = useNavigate();

  const goToScan = () => {
    navigate(QRSCAN, { replace: false });
  };

  const isOnline = useNavigatorOnLine();

  const onLogOut = useCallback(async () => {
    // Fermons la dialogue si necessaire
    if (openSignOutDialog) setOpenSignOutDialog(false);

    // Nous devons prendre en compte les cas avec MSAL et les connexions QC

    if (connectByMsal === false) {
      // traitement déconnexion par msal
      setConnectByMsal(false);
      setIsLogoutByMsal(true);
    }
    // Envoi des logs
    const sentLogs = await sendLogsToServerAsync(userUPN);
    if (sentLogs) {
      await logOutAsync(tAxios);
      // Arret de tous les observables.
      resetAllObservables();
    }
  }, [
    openSignOutDialog,
    resetAllObservables,
    logOutAsync,
    tAxios,
    sendLogsToServerAsync,
    userUPN,
    connectByMsal,
    setConnectByMsal,
    setIsLogoutByMsal,
  ]);

  const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);
  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);
  const handleMenuMoveTo = useCallback(() => {
    navigate(USER_SETTING);
  }, [navigate]);

  // Gestion de la dialogue
  const closeSignOutDialog = useCallback(() => {
    setOpenSignOutDialog(false);
  }, []);

  const wantLogout = useCallback(async () => {
    const displaySignOutDialog = await hasDraftsDcl(userUPN);

    if (!displaySignOutDialog) {
      await onLogOut();
    } else {
      setOpenSignOutDialog(displaySignOutDialog);
    }
  }, [hasDraftsDcl, onLogOut, userUPN]);

  useEffectOnce(() => {
    if (isLogoutByMsal) {
      onLogOut();
    }
  });

  const changeModule = () => {
    if (module === AppModulesIDs.MODULE_PARAM_CHANTIER_ID) {
      setModule(AppModulesIDs.MODULE_WEB_CLIENT_ID, true);
      return;
    }
    if (module === AppModulesIDs.MODULE_WEB_CLIENT_ID) {
      setModule(AppModulesIDs.MODULE_PARAM_CHANTIER_ID, true);
      return;
    }
  };

  const canChangeModule = !!(availableModules && Object.keys(availableModules).length >= 1);
  return {
    editableDeclaration,
    onLogOut,
    isOnline,
    goToScan,
    loadingRequests,
    userUPN,
    muiMode,
    switchMuiMode,
    handleMenuClick,
    handleMenuClose,
    handleMenuMoveTo,
    menuOpen,
    menuAnchorEl,
    isEditingCurrentDeclaration,
    sendingAttachments,
    attachmentsToSend,
    wantLogout,
    closeSignOutDialog,
    openSignOutDialog,
    changeModule,
    canChangeModule,
  };
};

export default useData;
