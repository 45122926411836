/* eslint-disable max-lines-per-function */
import React from 'react';
import { observer } from 'mobx-react';
import ErrorIcon from '@mui/icons-material/Error';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useData from './hooks';
import { FieldRendererProps } from './types';
import AttachmentQcField from '50.quickConnect.Fields/FieldsTypes/Attachments/AttachmentQcField';
import AudioQcField from '50.quickConnect.Fields/FieldsTypes/Attachments/AudioQcField';
import CodeReaderQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/CodeReaderQcField';
import FixedAttachmentQcField from '50.quickConnect.Fields/FieldsTypes/Attachments/FixedAttachmentQcField';
import FixedImageQcField from '50.quickConnect.Fields/FieldsTypes/Attachments/FixedImageQcField';
import PhotoQcField from '50.quickConnect.Fields/FieldsTypes/Attachments/PhotoQcField';
import SignatureQcField from '50.quickConnect.Fields/FieldsTypes/Attachments/SignatureQcField';
import AddressQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/AddressQcField';
import CheckBoxQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/CheckBoxQcField';
import ComputeQcField from '50.quickConnect.Fields/FieldsTypes/Others/ComputeQcField';
import CounterQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/CounterQcField';
import DataSourceQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/DataSourceQcField';
import DateTimeQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/DateTimeQcField';
import DigitsQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/DigitsQcField';
import GeolocationQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/GeolocationQcField';
import NumericQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/NumericQcField';
import SliderQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/SliderQcField';
import TimeQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/TimeQcField';
import TodoListQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/TodoListQcField';
import LabelQcField from '50.quickConnect.Fields/FieldsTypes/Others/LabelQcField';
import ReadOnlyValueQcField from '50.quickConnect.Fields/FieldsTypes/Others/ReadOnlyValueQcField';
import SeparatorQcField from '50.quickConnect.Fields/FieldsTypes/Others/SeparatorQcField';
import TextQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/TextQcField';
import RepeatableQcField from '50.quickConnect.Fields/FieldsTypes/Containers/RepeatableQcField';
import IncludeQcField from '50.quickConnect.Fields/FieldsTypes/Containers/IncludeQcField';
import ComboQcField from '50.quickConnect.Fields/FieldsTypes/Lists/ComboQcField';
import HierarchicalListQcField from '50.quickConnect.Fields/FieldsTypes/Lists/HierarchicalListQcField';
import CheckBoxListQcField from '50.quickConnect.Fields/FieldsTypes/Lists/CheckBoxListQcField';
import RadioListQcField from '50.quickConnect.Fields/FieldsTypes/Lists/RadioListQcField';
import DialogQcField from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField';
import GroupQcField from '50.quickConnect.Fields/FieldsTypes/Containers/GroupQcField';
import { FieldType } from '90.quickConnect.Models/enums';
import { CommonFieldProps } from '50.quickConnect.Fields/types';
import NotificationQcField from '50.quickConnect.Fields/FieldsTypes/Inputs/NotificationQcField';
import QcAction from '50.quickConnect.Fields/FieldsTypes/Others/QcAction';
import DrawQcField from '50.quickConnect.Fields/FieldsTypes/Attachments/DrawQcField';
import ImagesGroupQcField from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField';

const FieldRenderer = (fieldRendererProps: FieldRendererProps) => {
  const { flattenFields, updateDeclaration, setDeclaration, updateFieldErrors, updateItemsForThisField } =
    fieldRendererProps;
  const commonProps = fieldRendererProps as CommonFieldProps;
  const { fieldType, isVisible, labelSpan, labelClass } = useData(fieldRendererProps);

  if (isVisible === false) return <></>;
  switch (fieldType) {
    case FieldType.Text:
      return (
        <TextQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          flattenFields={flattenFields}
          {...commonProps}
        />
      );
    case FieldType.Digits:
      return (
        <DigitsQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
          flattenFields={flattenFields}
        />
      );
    case FieldType.Numeric:
      return (
        <NumericQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
          flattenFields={flattenFields}
        />
      );
    case FieldType.Separator:
      return (
        <SeparatorQcField
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.Label:
      return (
        <LabelQcField
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.Group:
      return (
        <GroupQcField
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          updateItemsForThisField={updateItemsForThisField}
          labelSpan={labelSpan}
          flattenFields={flattenFields}
          setDeclaration={setDeclaration}
          {...commonProps}
        />
      );
    case FieldType.ImagesGroup:
      return (
        <ImagesGroupQcField
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          updateItemsForThisField={updateItemsForThisField}
          labelSpan={labelSpan}
          flattenFields={flattenFields}
          setDeclaration={setDeclaration}
          {...commonProps}
        />
      );
    case FieldType.CheckBox:
      return (
        <CheckBoxQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.Combo:
      return (
        <ComboQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.HierarchicalList:
      return (
        <HierarchicalListQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.CheckBoxList:
      return (
        <CheckBoxListQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.RadioList:
    case FieldType.Alert:
      return (
        <RadioListQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.Dialog:
      return (
        <DialogQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          updateItemsForThisField={updateItemsForThisField}
          labelSpan={labelSpan}
          flattenFields={flattenFields}
          setDeclaration={setDeclaration}
          {...commonProps}
        />
      );
    case FieldType.Attachment:
      return (
        <DndProvider backend={HTML5Backend}>
          <AttachmentQcField
            labelClass={labelClass}
            updateDeclaration={updateDeclaration}
            labelSpan={labelSpan}
            updateFieldErrors={updateFieldErrors}
            {...commonProps}
          />
        </DndProvider>
      );
    case FieldType.Photo:
      return (
        <DndProvider backend={HTML5Backend}>
          <PhotoQcField
            labelClass={labelClass}
            updateDeclaration={updateDeclaration}
            labelSpan={labelSpan}
            updateFieldErrors={updateFieldErrors}
            {...commonProps}
          />
        </DndProvider>
      );
    case FieldType.Audio:
      return (
        <AudioQcField
          updateDeclaration={updateDeclaration}
          labelSpan={labelSpan}
          updateFieldErrors={updateFieldErrors}
          {...commonProps}
        />
      );
    case FieldType.Slider:
      return (
        <SliderQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          labelSpan={labelSpan}
          updateFieldErrors={updateFieldErrors}
          {...commonProps}
        />
      );
    case FieldType.Geolocation:
      return (
        <GeolocationQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          labelSpan={labelSpan}
          updateFieldErrors={updateFieldErrors}
          {...commonProps}
        />
      );
    case FieldType.Compute:
      return (
        <ComputeQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          labelSpan={labelSpan}
          updateFieldErrors={updateFieldErrors}
          {...commonProps}
        />
      );
    case FieldType.DateTime:
      return (
        <DateTimeQcField
          labelClass={labelClass}
          flattenFields={flattenFields}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.FixedImage:
      return (
        <FixedImageQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.Draw:
      return (
        <DrawQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.Signature:
      return (
        <SignatureQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.CodeReader:
    case FieldType.RfidReader:
      return (
        <CodeReaderQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.Counter:
      return (
        <CounterQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.Time:
      return (
        <TimeQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.RepeatableGroup:
      return (
        <RepeatableQcField
          updateDeclaration={updateDeclaration}
          updateItemsForThisField={updateItemsForThisField}
          updateFieldErrors={updateFieldErrors}
          setDeclaration={setDeclaration}
          labelSpan={labelSpan}
          flattenFields={flattenFields}
          {...commonProps}
        />
      );
    case FieldType.ReadOnlyValue:
      return (
        <ReadOnlyValueQcField
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.Address:
      return (
        <AddressQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.DataSource:
      return (
        <DataSourceQcField
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.TodoList:
      return (
        <TodoListQcField
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.FixedAttachment:
      return (
        <FixedAttachmentQcField
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.Include:
      return (
        <IncludeQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateItemsForThisField={updateItemsForThisField}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          setDeclaration={setDeclaration}
          flattenFields={flattenFields}
          {...commonProps}
        />
      );
    case FieldType.Notification:
      return (
        <NotificationQcField
          labelClass={labelClass}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.Action:
      return (
        <QcAction
          updateItemsForThisField={updateItemsForThisField}
          labelClass={labelClass}
          flattenFields={flattenFields}
          setDeclaration={setDeclaration}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
          labelSpan={labelSpan}
          {...commonProps}
        />
      );
    case FieldType.Array:
    case FieldType.Step:
    case FieldType.Unknow:
    case FieldType.Plugin:
    default:
      return <ErrorIcon />;
  }
};
export default observer(FieldRenderer);
