import { SxProps, Breakpoints } from '@mui/material';
import { TopBarHeight } from '10.quickConnect.app/components/shared/LayOut/TopBar/style';
import { dippedGreen200, dippedGreen800 } from '00.app/theme';
import { ImportanceLevel } from '90.quickConnect.Models/enums';

export const MainBox = (breakpoints: Breakpoints, isOpened: boolean): SxProps => ({
  display: 'flex',
  flexDirection: 'column',
  [breakpoints.up('md')]: {
    width: '50%',
    margin: isOpened ? '2em' : '2em auto 0',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  [breakpoints.down('md')]: {
    paddingLeft: '10px',
    paddingRight: '10px',
    marginBottom: '20px',
  },
});

const drawerWidth = '50vw';

export const RightDrawer: SxProps = {
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    marginTop: `calc(${TopBarHeight}px + 20px)`,
    paddingBottom: `${TopBarHeight}px`,
    width: drawerWidth,
  },
};

export const C2SImportant = (importance: ImportanceLevel): SxProps => ({
  '& > div': {
    borderLeft: `8px solid ${importance === ImportanceLevel.Important ? dippedGreen800 : dippedGreen200}`,
    borderRadius: '1em',
    borderBottom: `1px solid ${importance === ImportanceLevel.Important ? dippedGreen800 : dippedGreen200}`,
    borderTop: `1px solid ${importance === ImportanceLevel.Important ? dippedGreen800 : dippedGreen200}`,
    borderRight: `1px solid ${importance === ImportanceLevel.Important ? dippedGreen800 : dippedGreen200}`,
    padding: '16px',
    marginBottom: '1em',
  },
});
