import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { CHANTIER_SELECT, FORM } from '10.quickConnect.app/routes/paths';
import { useStore } from '30.quickConnect.Stores';
import { AppModulesIDs } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';

type ModuleType = AppModulesIDs | undefined;

const ModuleContext = createContext<ModuleType>(undefined);
const ModuleDispatchContext = createContext<(mod: ModuleType, redirect?: boolean) => void>(() => {});

export const useModuleContext = (): ModuleType => {
  return useContext(ModuleContext);
};

export function useModuleContextSetter() {
  return useContext(ModuleDispatchContext);
}

export const ModuleProvider = ({ children, value }: { value: ModuleType; children: React.ReactNode }): JSX.Element => {
  const navigate = useNavigate();
  const [module, setModule] = useState<ModuleType>(value);
  const {
    LoginStore: { availableModules },
  } = useStore();
  const {
    LoginStore: { setCurrentModule },
  } = useStore();

  function onModuleChange(newModule: ModuleType, redirect?: boolean) {
    const fallback = () => {
      setModule(AppModulesIDs.MODULE_WEB_CLIENT_ID);
      setCurrentModule(AppModulesIDs.MODULE_WEB_CLIENT_ID);
      navigate(FORM);
    };
    if (availableModules === undefined) {
      fallback();
      return;
    }

    const hasAccessToModule =
      Object.keys(availableModules).filter((key) => availableModules[key] === newModule).length > 0;

    if (hasAccessToModule) {
      setModule(newModule);
      setCurrentModule(newModule || AppModulesIDs.MODULE_WEB_CLIENT_ID);
      if (!redirect) {
        return;
      }
      if (newModule === AppModulesIDs.MODULE_PARAM_CHANTIER_ID) {
        navigate(CHANTIER_SELECT);
        return;
      }
      if (newModule === AppModulesIDs.MODULE_WEB_CLIENT_ID) {
        navigate(FORM);
        return;
      }
    }
    fallback();
  }

  return (
    <ModuleContext.Provider value={module}>
      <ModuleDispatchContext.Provider value={onModuleChange}>{children}</ModuleDispatchContext.Provider>
    </ModuleContext.Provider>
  );
};
