import React, { CSSProperties, FunctionComponent } from 'react';
import { Box, Card, Icon, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { Chantier, ChantierStatus } from './hookChantierList';

const cardStyle = (theme: Theme) => {
  return {
    maxWidth: '22%',
    width: '100%',
    height: '100%',
    padding: '1rem',
    border: 'solid 1px',
    borderColor: theme.palette.divider,
    cursor: 'pointer',
  };
};

const titleStyle: CSSProperties = {
  textAlign: 'center',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontWeight: 'bold',
};

const ChantierCard: FunctionComponent<Chantier> = (props: Chantier) => {
  const theme = useTheme();
  const { t } = useTranslation('moduleChantier');
  const { id, icon, published, status, name } = props;

  function goToChantier() {
    console.log('TODO KTY redirect au chantier avec ID !');
  }

  const getStatusBubble = () => {
    const bubbleSize = '10px';
    let color = status === ChantierStatus.CLOSED ? theme.palette.grey['500'] : undefined;
    if (!color) {
      color = published ? theme.palette.success.main : theme.palette.warning.main;
    }
    return <Box width={bubbleSize} height={bubbleSize} borderRadius={'50%'} bgcolor={color}></Box>;
  };

  const getStatusText = () => {
    switch (status) {
      case ChantierStatus.CLOSED:
        return t('chantier_status_to_complete');
      case ChantierStatus.INCOMPLETE:
        return t('chantier_status_closed');
      default:
        return '';
    }
  };

  return (
    <Card style={cardStyle(theme)} key={id} onClick={goToChantier}>
      <Stack alignItems={'center'} gap={'1rem'}>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
          <Typography variant={'caption'} display={'flex'} alignItems={'center'} gap={'0.2rem'}>
            {getStatusBubble()} {getStatusText()}
          </Typography>
          <Icon color={'success'}>
            <EditIcon />
          </Icon>
        </Stack>
        <img src={icon} width={'50%'} style={{ maxHeight: '150px', objectFit: 'contain' }} alt={name}></img>
        <Tooltip title={name}>
          <Typography variant={'caption'} style={titleStyle}>
            {name}
          </Typography>
        </Tooltip>
      </Stack>
    </Card>
  );
};

export default ChantierCard;
