import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { AppModulesIDs } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';
import {
  CHANTIER_SELECT,
  DECLARATIONS,
  EQUIPMENTS,
  FORMS,
  HOME,
  INBOXES,
  QRSCAN,
} from '10.quickConnect.app/routes/paths';
import { MAIN_PAGE_SUBROUTES } from '10.quickConnect.app/routes/routes';
import { HomeTabs } from '90.quickConnect.Models/enums';
import { useStore } from '30.quickConnect.Stores';
import { useNavigatorOnLine } from '80.quickConnect.Core/hooks';
import { useModuleContextSetter } from '00.app/module/moduleContext';

const useData = (): UseDataProps => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // On récupère le store
  const {
    InboxesStore: { getInboxItems, inboxesTotalNumber },
    EquipmentsStore: { getEquipmentsFromDb },
    DeclarationStore: {
      sendDeclarationToAPIAsync,
      sendAPI,
      backupDclContext,
      isEditingCurrentDeclaration,
      setIsEditingCurrentDeclaration,
      editableDeclaration,
      setEditableDeclaration,
      getDeclarationList,
    },
    LoginStore: {
      signInInfos: { userUPN, module },
    },
    UserSettingsStore: { syncDate },
    SessionStore: { lastURLSaved },
    CommonStore: { setWorkInProgress, setWorkDone },
    sendLocalDataBaseRecordsCount,
  } = useStore();

  const { t } = useTranslation();
  const onLine = useNavigatorOnLine();
  const setModule = useModuleContextSetter();

  const sendAPIRef = useRef<boolean>(true);

  useLayoutEffect(() => {
    if (!backupDclContext) {
      if (isEditingCurrentDeclaration) setIsEditingCurrentDeclaration(false);
      if (editableDeclaration) setEditableDeclaration(false);
    }
    if (sendAPIRef.current === true && onLine) {
      (async () => {
        await sendLocalDataBaseRecordsCount(syncDate);
        await sendDeclarationToAPIAsync();
      })();

      return () => {
        if (onLine) sendAPIRef.current = false;
      };
    }

    if (!onLine) {
      sendAPIRef.current = true;
    }
  }, [
    onLine,
    sendLocalDataBaseRecordsCount,
    syncDate,
    sendDeclarationToAPIAsync,
    userUPN,
    setWorkInProgress,
    setWorkDone,
    backupDclContext,
    isEditingCurrentDeclaration,
    editableDeclaration,
    setIsEditingCurrentDeclaration,
    setEditableDeclaration,
  ]);

  // On set le state
  const [valueTabIndex, setValueTabIndex] = useState<HomeTabs>(() => {
    switch (pathname.toLowerCase()) {
      case EQUIPMENTS:
        return HomeTabs.Equipments;
      case INBOXES:
        return HomeTabs.Inboxes;
      case DECLARATIONS:
        return HomeTabs.Declarations;
      case QRSCAN:
        return HomeTabs.QrScan;
      case HOME:
      case FORMS:
      default:
        return HomeTabs.Forms;
    }
  });
  const valueTabIndexs = useMemo(() => {
    switch (pathname.toLowerCase()) {
      case EQUIPMENTS:
        return HomeTabs.Equipments;
      case INBOXES:
        return HomeTabs.Inboxes;
      case DECLARATIONS:
        return HomeTabs.Declarations;
      case QRSCAN:
        return HomeTabs.QrScan;
      case HOME:
      case FORMS:
      default:
        return HomeTabs.Forms;
    }
  }, [pathname]);

  useEffect(() => {
    setValueTabIndex(valueTabIndexs);
  }, [valueTabIndexs]);

  useEffect(() => {
    if (module !== AppModulesIDs.MODULE_WEB_CLIENT_ID) {
      setModule(AppModulesIDs.MODULE_WEB_CLIENT_ID);
    }
  }, [module, setModule]);

  // On ajoute des hooks
  useEffect(() => {
    const getUrlByModule = () => {
      switch (module) {
        case AppModulesIDs.MODULE_PARAM_CHANTIER_ID:
          return CHANTIER_SELECT;
        default:
          return FORMS;
      }
    };

    // Par défault, on envoie vers la liste des formulaires
    const navUrl = lastURLSaved && backupDclContext.length === 0 ? lastURLSaved : getUrlByModule();
    if (pathname === HOME) navigate(navUrl, { replace: true });
  }, [navigate, pathname, lastURLSaved, backupDclContext, module]);

  // On ajoute des hooks
  const handleTabChange = useCallback(
    (newTab: HomeTabs) => {
      setValueTabIndex(newTab);
      const target = MAIN_PAGE_SUBROUTES.find((tab) => tab.homeTab === newTab);
      if (target) navigate(target.path ?? '/');
      else navigate(FORMS, { replace: true });
    },
    [navigate],
  );

  const getInboxesJob = useMemo(() => [uuidv4(), t('inboxes:jobMessage.getInboxesJob')] as [string, string], [t]);

  const shouldGetInboxesList = useRef(true);

  useEffect(() => {
    if (shouldGetInboxesList.current && !lastURLSaved) {
      shouldGetInboxesList.current = false;
      setWorkInProgress(getInboxesJob);
      getInboxItems().finally(() => {
        setWorkDone(getInboxesJob[0]);
      });
    }
  }, [getInboxItems, getInboxesJob, setWorkDone, setWorkInProgress, lastURLSaved]);

  const getDeclarationsJob = useMemo(
    () => [uuidv4(), t('declarations:job_message_get_declarations_job')] as [string, string],
    [t],
  );

  const shouldGetDeclarationsList = useRef(true);

  useEffect(() => {
    if (shouldGetDeclarationsList.current && !lastURLSaved) {
      shouldGetInboxesList.current = false;
      setWorkInProgress(getDeclarationsJob);
      getDeclarationList(userUPN).finally(() => {
        setWorkDone(getDeclarationsJob[0]);
      });
    }
  }, [getDeclarationList, getDeclarationsJob, setWorkDone, setWorkInProgress, lastURLSaved, userUPN]);

  const getEquipmentsJob = useMemo(
    () => [uuidv4(), t('equipment:jobMessage.getEquipmentsJob')] as [string, string],
    [t],
  );

  const shouldGetEquipmentsList = useRef(true);

  useEffect(() => {
    if (shouldGetEquipmentsList.current && !lastURLSaved) {
      shouldGetEquipmentsList.current = false;
      setWorkInProgress(getEquipmentsJob);
      getEquipmentsFromDb().finally(() => {
        setWorkDone(getEquipmentsJob[0]);
      });
    }
  }, [getEquipmentsFromDb, getEquipmentsJob, setWorkDone, setWorkInProgress, lastURLSaved]);

  return {
    handleTabChange,
    valueTabIndex,
    inboxesTotalNumber,
    sendAPI,
  };
};

export default useData;
